
.TournamentDetailPage {

  .Content {
    margin-bottom: 40px;
    white-space: pre-line;
  }

  &__actions {
    display: block;
    gap: 24px;
    margin: 40px 0;

    @media (max-width: $break-medium) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__action {
    display: inline-block;
    min-height: 44px;
    margin-right: 4px;
    margin-bottom: 4px;

    @media (max-width: $break-medium) {
      .Button {
        width: 100%;
        justify-content: center;
      }
    }


    &--text {
      display: inline-flex;
      align-items: center;
      background: var(--color-grey);
      padding: 4px 16px;
      font-size: 18px;
      line-height: 18px;
      text-align: left;
      color: var(--color-blue-10);
      gap: 4px;

      a {
        color: var(--color-blue-10);
      }

      @media (max-width: $break-medium) {
        padding: 8px 16px;
        justify-content: center;
      }
    }
  }
}
