

.TournamentCard {
  display: flex;
  position: relative;
  margin: 24px 0;
  border: 1px solid var(--color-grey);
  padding: 24px;
  gap: 24px;
  text-align: left;
  opacity: .7;

  &--future {
    opacity: 1;
  }

  @media (max-width: $break-small) {
    padding: 16px;
    margin: 16px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
  }

  &__map {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 33%;

    &:after {
      content: "";
      display: block;
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,.5) 100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    @media (max-width: $break-small) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__flag {
    color: var(--color-text-secondary);
    text-align: right;
    font-size: 14px;
    font-weight: bold;
  }

  &__title:before {
    background: url("../../../assets/svg/icon-cross-outline.svg") no-repeat center;
    background-size: 16px 16px;
  }

  &--future &__title:before {
    background-image: url("../../../assets/svg/icon-cross-green.svg");
    background-size: 16px 16px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
    font-family: $font-primary;
    margin: 0;

    &:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }

  &__subtitle {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-blue-20);
    text-transform: uppercase;
    font-family: $font-primary;
  }

  &__description {
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text-primary);
  }

  &:hover {
    background: var(--color-green-overlay-08);
    border-color: var(--color-green);
  }
}
