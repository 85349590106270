
$filter-break-medium: 1024px;

.TournamentFilter {
  border-top: 1px solid var(--color-grey-10);
  border-bottom: 1px solid var(--color-grey-10);
  margin-bottom: 40px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    gap: 24px;

    @media (max-width: $filter-break-medium) {
      flex-direction: column;
      align-items: flex-start;
    }

    & > * {
      margin: 0;
    }

    &Actions {
      display: flex;
      align-content: center;
      gap: 24px;

      & > * {
        margin: 0;
      }

      @media (max-width: $filter-break-medium) {
        width: 100%;
      }

      @media (max-width: $break-small) {
        flex-direction: column-reverse;
        gap: 16px;
      }
    }
  }

  &__search {
    margin: 0;

    @media (max-width: $filter-break-medium) {
      flex-grow: 1;
    }
  }

  &__panel {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 24px;
    padding: 24px 0;

    @media (max-width: $filter-break-medium) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 16px;
    }

    .FormField {
      margin: 0;
    }
  }

  &__container {
    position: sticky;
    top: 88px;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.72);
    z-index: 2;

    @media (max-width: $filter-break-medium) {
      position: static;
    }
  }
}
