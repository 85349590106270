

.TournamentForm {
  max-width: 800px;
  margin: 80px auto;
  text-align: left;

  @media (max-width: $break-small) {
    margin: 40px auto;
  }

  .FormField {
    width: 100%;
    margin: 0;
  }

  &__field {
    margin-bottom: 32px;

    &--small {
      width: 50%;

      @media (max-width: $break-small) {
        width: 100%;
      }
    }

    &--split {
      display: flex;
      gap: 24px;

      @media (max-width: $break-small) {
        flex-direction: column;
      }
    }

    &--noMargin {
      margin-bottom: 0;
    }
  }

  &__section {
    margin-bottom: 64px;
    border: 1px solid var(--color-grey);
    padding: 24px 24px 0;

    @media (max-width: $break-small) {
      margin-bottom: 40px;
      padding: 0;
      border: none;
    }

    h2 {
      margin: 0 0 24px;
    }
  }

  &__helper {
    font-size: 14px;
    line-height: 16px;
    margin-top: 8px;
    color: var(--color-text-secondary);
  }

  &__map {
    height: 320px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 32px;
    border: 1px solid var(--color-black-16);
    background: var(--color-black-08);
  }

  &__action {
    text-align: center;
  }
}
