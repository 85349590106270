

.RankingTable {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;

  @media (max-width: 918px) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  @media (max-width: $break-small) {
    grid-gap: 8px;
  }

  &__item {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border: 1px solid var(--color-grey);

    @media (max-width: $break-small) {
      gap: 16px;
      padding: 16px;
    }

    &--promoted {
      background: var(--color-green-overlay-08);
      border-color: var(--color-green);
    }
  }

  &__order {
    font-family: $font-primary;
    font-size: 20px;
    font-weight: bold;
    color: var(--color-grey-20);
    min-width: 56px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (max-width: $break-small) {
      min-width: 40px;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    flex-grow: 1;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (max-width: $break-small) {
      gap: 8px;
    }
  }

  &__flag {
    display: block;
    width: 35px !important;
    height: 27px !important;
    border: 1px solid var(--color-grey);

    @media (max-width: $break-small) {
      width: 21px !important;
      height: 16px !important;
    }
  }

  &__points {
    text-align: right;

    strong {
      display: block;
      font-size: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      font-size: 13px;
      line-height: 13px;
      color: var(--color-text-secondary);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}