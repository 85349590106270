
.HomePage {

  &__tiles {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 24px;
    margin-bottom: 80px;

    @media (max-width: 780px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-row-gap: 24px;
    }

    @media (max-width: $break-small) {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      margin-bottom: 40px;
    }

    &Item {
      background: var(--color-grey);
      height: 128px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;

      @media (max-width: 1080px) {
        gap: 8px;
        height: 96px;
      }

      @media (max-width: 780px) {
        justify-content: flex-start;
        padding-left: 24px;
      }

      @media (max-width: $break-xsmall) {
        padding-left: 16px;
      }

      &Icon {
        width: 56px;
        height: 56px;
        user-select: none;

        @media (max-width: 1080px) {
          width: 40px;
          height: 40px;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &Value {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: normal;
        color: var(--color-blue-10);
        text-transform: uppercase;
        font-family: $font-primary;
        white-space: nowrap;

        @media (max-width: 1080px) {
          font-size: 20px;
          line-height: 20px;
        }
      }

      &Label {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 14px;
        letter-spacing: normal;
        color: var(--color-blue-10);
        text-transform: uppercase;
        font-family: $font-primary;
      }

      &Content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 24px;
    min-height: 44px;

    @media (max-width: $break-small) {
      margin-bottom: 16px;
    }

    &--withOffset {
      margin-top: 80px;
    }

    h1,
    h2 {
      margin: 0;
    }

    &Link {
      @media (max-width: $break-small) {
        display: none;
      }
    }
  }

  &__description {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-secondary);
  }

  &__newsLink {
    display: none;

    @media (max-width: $break-small) {
      display: block;
      margin-top: 16px;
    }
  }

  &__registerLinks {

  }

  &__registerLinksList {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 24px;

    @media (max-width: $break-medium) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-xsmall) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__registerLinksItem {
    display: block;
    background-color: var(--color-black);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    aspect-ratio: 3 / 4;
    position: relative;
    transition: box-shadow ease-in-out $animation-speed;
    will-change: box-shadow;

    @supports not (aspect-ratio: auto) {
      padding-top: 100%;
      height: 0;
      position: relative;
      overflow: hidden;
    }

    @media (max-width: $break-medium) {
      aspect-ratio: 1 / 1;
    }

    @media (max-width: $break-medium) {
      aspect-ratio: 2 / 1;
    }

    &--player {
      background-image: url("../../assets/cover-player.jpeg");
    }

    &--team {
      background-image: url("../../assets/cover-team.jpeg");
    }

    &--league {
      background-image: url("../../assets/cover-league.jpeg");
    }

    &--referee {
      background-image: url("../../assets/cover-referee.jpeg");
    }

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .9) 80%);
      padding: 64px 24px 24px;
      font-family: $font-primary;
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--color-white);
    }

    &:hover {
      box-shadow: 0 11px 15px -7px rgba(131, 240, 163, .2), 0 9px 46px 8px rgba(131, 240, 163, .12), 0 24px 38px 3px rgba(131, 240, 163, .14);
    }
  }

  &__columns {
    display: grid;
    grid-template-columns: minmax(0, 2.5fr) minmax(0, 1.5fr);
    grid-gap: 24px;
    margin-bottom: 24px;

    @media (max-width: $break-large) {
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 80px;
    }

    & > div {
      display: flex;
      flex-direction: column;
    }
  }

  &__coverLinks {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;

    @media (max-width: $break-xsmall) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &--small {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &Item {
      display: block;
      position: relative;
      aspect-ratio: 16 / 9;
      background: var(--color-black);
      cursor: pointer;
      overflow: hidden;

      &--superliga {
        background-image: url("../../assets/bg-superliga.jpeg");
      }

      &--cnp {
        background-image: url("../../assets/bg-cnp.jpeg");
      }

      &--superfinale {
        background-image: url("../../assets/bg-superfinale.jpeg");

        @media (max-width: $break-large) {
          aspect-ratio: 32 / 9;
        }

        @media (max-width: $break-medium) {
          aspect-ratio: 16 / 9;
        }
      }

      background-size: cover;
      background-position: center;

      @supports not (aspect-ratio: auto) {
        padding-top: 60%;
        height: 0;
      }

      &Inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;

        @media (min-width: $break-small + 1px) and (max-width: $break-large) {
          position: relative;
          padding: 40px 24px;
          flex-direction: row;
        }

        @media (max-width: $break-small) {
          position: relative;
          flex-direction: column;
          padding: 24px;
        }
      }

      &Brand {
        display: block;
        width: 128px;
      }

      &Description {
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        padding: 0 32px;
        color: var(--color-white);
        max-width: 400px;

        @media (min-width: $break-small + 1px) and (max-width: $break-large) {
          text-align: left;
        }

        @media (max-width: $break-small) {
          padding: 0;
        }
      }

      &Title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .9) 80%);
        padding: 64px 24px 24px;
        font-family: $font-primary;
        font-size: 24px;
        line-height: 32px;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--color-white);
      }
    }
  }
}