

.TournamentListItem {
  display: flex;
  padding: 24px;
  background: var(--color-grey);
  gap: 24px;
  align-items: center;
  cursor: pointer;

  @media (max-width: $break-small) {
    padding: 16px;
    gap: 16px;
  }

  &__icon {
    display: block;
    width: 18px;
    height: 18px;
    background: url("../../../assets/svg/icon-cross-disabled.svg") no-repeat center;
    background-size: 18px 18px;

    @media (max-width: $break-small) {
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__title {
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    font-family: $font-primary;
    margin: 0;
  }

  &__subtitle {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-blue-10);
  }

  &:hover {
    background: var(--color-blue-10);
  }

  &:hover &__icon {
    background-image: url("../../../assets/svg/icon-cross-green.svg");
  }

  &:hover &__title,
  &:hover &__subtitle {
    color: var(--color-white);
  }
}