
.TournamentCreatePage {

  h1 {
    text-align: center;
    justify-content: center;

    @media (max-width: $break-small) {
      text-align: left;
      justify-content: flex-start;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: var(--color-green-overlay-08);
    border: 1px solid var(--color-green);
    margin: 80px 0;
    gap: 8px;

    @media (max-width: $break-small) {
      padding: 16px;
    }

    &Title,
    &Message {
      margin: 0;
    }

    &Title {
      color: var(--color-blue-20);
    }

    &Message {
      color: var(--color-text-secondary);
      font-size: 16px;
      line-height: 20px;
      max-width: 600px;
      margin: auto;
    }

    .Button {
      margin-top: 24px;

      @media (max-width: $break-small) {
        margin-top: 16px;
      }
    }
  }
}