

.TournamentListPage {

  &__map {
    background: var(--color-grey-20);
    height: calc(100vh - 258px - 24px - 108px - 64px);
    min-height: 360px;
    max-height: 1024px;
    margin: 24px 0;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%);
    overflow: hidden;
    border-radius: 16px;

    h3,
    p {
      margin: 0;
      text-align: left;
    }

    p {
      margin-top: 16px;
    }

    a.Button {
      text-transform: uppercase;
      margin-top: 16px;
    }

    &Footer {
      width: 100%;
      text-align: left;
    }
  }
}
