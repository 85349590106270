

.TournamentEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  min-height: 50vh;

  &__icon {
    display: block;
    width: 32px;
    height: 32px;
    background: url("../../../assets/svg/error-disabled.svg") no-repeat center;
    background-size: 32px 32px;
    opacity: .4;
  }

  &__text {
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    font-family: $font-primary;
    color: var(--color-text-secondary);
    user-select: none;
  }
}