$structure-b-small: $break-large + 80px;

.Structure {
  --gap-color: var(--color-grey-10);
  --gap-size: 40px;
  --gap-line: 4px;
  --bg: var(--color-grey);
  --width: 180px;

  @media (max-width: $structure-b-small) {
    --gap-size: 20px;

    &__section > &__list {
      padding-left: 0;
      margin-left: 0;
    }
  }

  &__section {
    border-bottom: var(--gap-line) solid var(--gap-color);
    padding: var(--gap-size) 0;

    @media (max-width: $structure-b-small) {
      padding: 0 var(--gap-size);
    }

    &:last-child {
      border-bottom: none;
    }
  }

  margin: 40px 0 -80px 0;
  background: var(--bg);
  overflow: hidden;

  @media (max-width: $break-medium) {
    margin: 40px 0;
  }

  &__position {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--color-white);
    gap: 4px;
    text-align: center;
    padding: 12px 16px;
    font-family: $font-native;
    text-transform: uppercase;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%);
    position: relative;
    width: var(--width);
    z-index: 1;

    @media (max-width: $structure-b-small) {
      text-align: left;
      align-items: flex-start;
      width: auto;
    }

    &Title {
      display: block;
      font-size: 13px;
      color: var(--color-text-primary);
      font-weight: bold;
    }

    &Subtitle {
      display: block;
      font-size: 10px;
      color: var(--color-text-secondary);
    }

    &--filled {
      background: var(--color-blue-20);
    }

    &--filled &Title {
      color: var(--color-white);
    }

    &--filled &Subtitle {
      color: var(--color-white-32);
    }

    @media (max-width: $structure-b-small) {
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: calc(-1 * var(--gap-size));
        top: calc(50% - var(--gap-line) / 2);
        border-bottom: var(--gap-line) solid var(--gap-color);
        border-left: none;
        width: 20px;
        height: 0;
      }
    }
  }

  &__list {
    display: flex;
    gap: var(--gap-size);
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    position: relative;
    // outline: 2px solid green;

    @media (max-width: $structure-b-small) {
      flex-direction: column;
      padding: var(--gap-size);
      margin-left: var(--gap-size);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(-1 * var(--gap-size) / 2);
      width: 100%;
      height: 0;
      border-bottom: var(--gap-line) solid var(--gap-color);

      @media (max-width: $structure-b-small) {
        top: 0;
        left: 0;
        width: 0;
        height: calc(100% - var(--gap-size));
        border-bottom: none;
        border-left: var(--gap-line) solid var(--gap-color);
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: calc(-1 * var(--gap-size));
      left: 50%;
      width: 0;
      height: calc(var(--gap-size) / 2);
      border-left: var(--gap-line) solid var(--gap-color);

      @media (max-width: $structure-b-small) {
        display: none;
      }
    }

    &--single:before {
      display: none;
    }
  }

  &__item {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-size);
    position: relative;

    @media (max-width: $structure-b-small) {
      width: 100%;
      align-items: flex-start;
      gap: 0;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(-1 * var(--gap-size) / 2);
      left: 50%;
      width: 0;
      height: calc(var(--gap-size) / 2);
      border-left: var(--gap-line) solid var(--gap-color);

      @media (max-width: $structure-b-small) {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(-1 * var(--gap-size) / 2);
      width: calc(50% - var(--gap-line));
      height: 0;
      border-bottom: var(--gap-line) solid var(--bg);
      display: none;
    }
  }

  &__item--horizontal &__list {

  }

  &__item--vertical {
    gap: calc(var(--gap-size) / 2);

    @media (max-width: $structure-b-small) {
      gap: 0;
    }
  }

  &__item--vertical &__list {
    flex-direction: column;
    padding-left: calc(var(--gap-size) / 2);
    margin-left: calc(var(--gap-size) / 2);
    gap: calc(var(--gap-size) / 2);

    @media (max-width: $structure-b-small) {
      margin-left: var(--gap-size);
      padding-left: var(--gap-size);
      gap: var(--gap-size);
    }
  }

  &__item--vertical &__list:before {
    border-bottom: none;
    height: calc(100% - var(--gap-line) + (var(--gap-size) / 2));
    border-left: var(--gap-line) solid var(--gap-color);
    width: 0;
    left: 0;
    top: calc(-1 * var(--gap-size));

    @media (max-width: $structure-b-small) {
      top: 0;
      left: 0;
      width: 0;
      height: calc(100% - var(--gap-size));
      border-bottom: none;
      border-left: var(--gap-line) solid var(--gap-color);
    }
  }

  &__item--vertical &__list:after {
    display: none;
  }

  &__item--vertical > &__list > &__item:before {
    border-left: 0;
    border-bottom: var(--gap-line) solid var(--gap-color);
    width: calc(var(--gap-size) / 2);
    left: calc(-1 * var(--gap-size) / 2);
    top: 50%;
    height: 0;
  }

  &__item--vertical > &__list > &__item:last-child:after {
    width: 0;
    height: 50%;
    border-bottom: none;
    border-left: var(--gap-line) solid var(--bg);
    top: calc(50% + var(--gap-line));
    left: calc(-1 * var(--gap-size) / 2);

    @media (max-width: $structure-b-small) {
      width: 0;
      height: 50%;
      border-bottom: none;
      right: auto;
      top: auto;;
      bottom: 0;
      left: calc(-1 * var(--gap-size));
      border-left: var(--gap-line) solid var(--bg);
    }
  }

  &__item--vertical > &__list > &__item > &__position {
    align-items: flex-start;
    width: calc(var(--width) - var(--gap-size));
    text-align: left;

    @media (max-width: $structure-b-small) {
      width: auto;
    }
  }

  &__item:last-child:after {
    right: 0;
    display: block;
    width: calc(50% - var(--gap-line));

    @media (max-width: $structure-b-small) {
      width: 0;
      height: 50%;
      border-bottom: none;
      right: auto;
      top: auto;;
      bottom: 0;
      left: calc(-1 * var(--gap-size));
      border-left: var(--gap-line) solid var(--bg);
    }
  }

  &__item:first-child:after {
    left: 0;
    display: block;
    width: 50%;
  }

  &__item:last-child:first-child:after {
    display: none;
  }

  @media (max-width: $structure-b-small) {

    &__item:last-child > &__list {
      padding-bottom: 0;
    }

    &__item:last-child > &__list:after {
      left: calc(-1 * var(--gap-size) * 2);
      height: 100%;
      border-color: var(--bg);
      display: block;
      top: calc(-1 * var(--gap-size));
    }
  }
}