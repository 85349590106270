
.ReprePromote {


  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: $break-small) {
      gap: 0;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;

    &Title {
      font-size: 20px;
      font-family: $font-primary;
      text-transform: uppercase;
      font-weight: bolder;
      color: var(--color-blue-20);
      margin-bottom: 16px;
      margin-top: 24px;
    }
  }

  &__half {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 24px;

    @media (max-width: $break-small) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__typeSelect {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;

    @media (max-width: $break-small) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__type {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-align: left;
    border: 1px solid var(--color-black-08);
    padding: 16px;
    margin: 1px;
    gap: 8px;

    &--selected {
      border-color: var(--color-green);
      border-width: 2px;
      background: var(--color-green-overlay-08);
      margin: 0;
    }

    strong {
      font-size: 20px;
      font-weight: normal;
      color: var(--color-blue-20);
    }

    ul {
      list-style-type: circle;
      list-style-position: inside;
      padding-left: 4px;
      padding-right: 4px;
      color: var(--color-text-secondary);
      font-family: $font-native;
      text-transform: none;
      flex-grow: 1;
    }

    li {

    }

    &Price {
      font-size: 20px;
      font-weight: bolder;
    }
  }

  &__payment {
    border: 1px solid var(--color-black-16);
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $break-small) {
      padding: 16px;
      text-align: left;
    }

    h1 {
      justify-content: center;
      margin: 0 0 16px;
    }

    p {
      max-width: 560px;
    }

    &Info {
      display: flex;
      align-items: center;
      text-align: left;
      padding: 16px 0;

      @media (max-width: $break-small) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        padding-top: 0;
      }

      &Column {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &Row {
        display: flex;
        flex-direction: column;
      }

      img {
        width: 250px;
        height: 250px;

        @media (max-width: $break-small) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__error {
    border: 1px solid var(--color-red);
    background: var(--color-red-overlay-08);
    padding: 16px;
    color: var(--color-red-10);
    margin: 16px 0;
  }
}
