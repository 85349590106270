
.UnionListPage {

  &__subtitle {
    @media (max-width: 796px) {
      display: none;
    }
  }

  &__map {
    position: relative;
    width: 100%;
    height: 548px;
    margin: 64px 0;

    @media (max-width: 1024px) {
      height: 420px;
      width: 734px;
      margin: 64px auto;
    }

    @media (max-width: 796px) {
      display: none;
    }

    &Dialog {
      position: absolute;
      left: attr(data-xl-left px);
      top: attr(data-xl-top px);

      &Box {
        position: absolute;
        top: 48px;
        left: -94px;
        background: var(--color-white);
        box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
        padding: 16px;
        width: 220px;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-4px);
        transition: visibility ease-in-out $animation-speed, opacity ease-in-out $animation-speed, transform ease-in-out $animation-speed;
        z-index: 1;

        &:before {
          content: "";
          display: block;
          position: absolute;
          background: url("../../assets/svg/icon-menu-arrow.svg") no-repeat center;
          background-size: 22px 11px;
          width: 22px;
          height: 11px;
          top: -11px;
          left: 50%;
          transform: translateX(-50%);
        }

        &--opened {
          visibility: visible;
          opacity: 1;
          transform: none;
        }
      }

      &Title {
        font-family: $font-primary;
        text-transform: uppercase;
        color: var(--color-blue-20);
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      &Subtitle {
        font-family: $font-primary;
        text-transform: uppercase;
        color: var(--color-blue-20);
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 16px;
      }

      &Button {
        display: block;
        width: 100%;

        .Button {
          width: 100%;
          justify-content: center;
          height: 32px;
        }
      }
    }

    &Point {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 0;
      left: 0;
      background: var(--color-blue) url("../../assets/svg/icon-cross-green.svg") no-repeat center;
      background-size: 14px 14px;
      border-radius: 50%;
      transition: transform ease-in-out $animation-speed;

      &:hover {
        cursor: pointer;
        transform: scale(1.1, 1.1);
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;

    @media (max-width: 796px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__union {
    display: block;
    background: var(--color-grey);
    padding: 32px;

    @media (max-width: 796px) {
      padding: 24px;
    }

    &Brand {
      display: block;
      width: 64px;
      height: 64px;
      background: var(--color-white);
      border-radius: 50%;
      overflow: hidden;
      padding: 8px;
      margin-bottom: 24px;

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &Title {
      margin: 0;
      font-size: 20px;
      line-height: 24px;
    }

    &Subtitle {
      font-family: $font-primary;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 18px;
    }

    &Description {
      margin: 24px 0;
      color: var(--color-text-primary);
      font-size: 14px;
      line-height: 18px;
    }
  }
}