
.NewsDetailPage,
.TournamentDetailPage {

  &__title {
    margin-bottom: 8px;

    @media (max-width: $break-xsmall) {
      font-size: 24px;
      line-height: 28px;
      margin-top: 24px;

      &:before {
        display: none;
      }
    }
  }

  &__subtitle {
    display: flex;
    gap: 6px;
    font-size: 14px;
    font-family: $font-primary;
    color: var(--color-blue-10);
    text-transform: uppercase;
    font-weight: 500;
    padding-left: 40px;

    @media (max-width: $break-medium) {
      display: block;
    }

    @media (max-width: $break-xsmall) {
      padding-left: 0;
      font-size: 12px;
    }

    span {
      display: flex;
      gap: 6px;

      @media (max-width: $break-medium) {
        display: inline;
        padding-left: 4px;

        &:first-child {
          padding-left: 0;
        }
      }

      &:before {
        content: "|";
        padding-right: 4px;
      }

      &:first-child:before {
        display: none;
      }
    }
  }

  &__perex {
    background: var(--color-grey);
    color: var(--color-text-primary);
    padding: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin: 40px 0;
  }
}