
.UnionSlider {
  margin-top: 24px;
  position: relative;
  background: var(--color-grey) url("../../../assets/bg-unions.jpeg") no-repeat center;
  background-size: cover;
  overflow: hidden;
  display: flex;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(11, 28, 99, .64), rgba(11, 28, 99, .72));
  }

  @media (min-width: $break-large + 1px) {
    height: 562px;
  }

  @media (min-width: $break-small + 1px) and (max-width: $break-large) {
    aspect-ratio: unset;

    @supports not (aspect-ratio: auto) {
      padding-top: 0;
      height: auto;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    @media (min-width: $break-large + 1px) {
      @supports not (aspect-ratio: auto) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &[data-keen-slider-v] {
      flex-wrap: wrap;
    }

    &[data-keen-slider-v] &__slide {
      width: 100%;
    }

    &[data-keen-slider-moves] * {
      pointer-events: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    padding: 32px 0;

    @media (max-width: $break-small) {
      gap: 16px;
    }
  }

  &__brand {
    display: block;
    width: 220px;

    @media (max-width: $break-small) {
      width: 160px;
    }
  }

  &__image {
    display: block;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: var(--color-white);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.08);
    position: relative;
    margin-bottom: 16px;
    min-height: 96px;
    min-width: 96px;

    &Inner {
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 16px;
      left: 16px;
      right: 16px;
      bottom: 16px;

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__description {
    text-align: center;
    padding: 0 120px;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-white);

    @media (max-width: $break-small) {
      padding: 0 64px;
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: normal;
    font-family: $font-primary;
    text-transform: uppercase;
    color: var(--color-white);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    cursor: pointer;
    background: none;

    i {
      color: var(--color-white-32)
    }

    @media (max-width: $break-small) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__dropdown {
    position: relative;

    &List {
      position: absolute;
      top: 36px;
      left: 50%;
      width: 100%;
      min-width: 320px;
      transform: translateX(-50%);
      background: var(--color-white);
      box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 14%);
      border-radius: 4px;
      max-height: 200px;
      overflow: auto;
      z-index: 1;
      padding: 8px 0;
      opacity: 0;
      visibility: hidden;
      animation: UnionDialogOpen ease-in-out $animation-speed forwards;
      will-change: opacity, transform;
    }

    &Item {
      display: block;
      padding: 8px 16px;
      font-family: $font-primary;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        background: var(--color-black-08);
      }
    }
  }

  &__numberList {
    display: flex;
    gap: 40px;

    @media (max-width: $break-small) {
      gap: 24px;
    }
  }

  &__numberItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    color: var(--color-white-32)
  }

  &__numberValue {
    font-size: 46px;
    font-weight: 500;
    line-height: 46px;
    font-family: $font-primary;
    text-transform: uppercase;
    color: var(--color-white);

    @media (max-width: $break-small) {
      font-size: 32px;
      line-height: 32px;
    }
  }

  &__numberLabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    font-family: $font-primary;
    text-transform: uppercase;

    @media (max-width: $break-small) {
      font-size: 12px;
      line-height: 12px;
    }
  }

  &__button {
    display: inline-flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 50%;
    background: var(--color-white-08);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    color: var(--color-white);
    border: none;
    appearance: none;
    cursor: pointer;
    width: 48px;
    height: 48px;
    font-size: 20px;
    line-height: 20px;
    transform: translateY(-50%);
    outline: none;

    &--left {
      left: 32px;

      @media (max-width: $break-small) {
        left: 0;
        top: 72px;
      }
    }

    &--right {
      right: 32px;

      @media (max-width: $break-small) {
        right: 0;
        top: 72px;
      }
    }
  }
}


@keyframes UnionDialogOpen {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(-50%, -8px, 0);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%);
  }
}