

.TournamentMap {
  aspect-ratio: 16 / 8;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%);
  position: relative;
  margin-top: 24px;

  @supports not (aspect-ratio: auto) {
    padding-top: 60%;
    height: 0;
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
    z-index: 1;
  }

  &__container {
    margin-bottom: 80px;
  }

  &__button {
    position: absolute;
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    .Button {
      background: var(--color-white);
    }
  }
}
