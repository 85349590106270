
.ContactPage {

  &__personPhoto {
    &--renePazdera {
      background-image: url("../../assets/rene-pazdera.jpeg");
    }
    &--jaroslavKara {
      background-image: url("../../assets/jaroslav-kara.jpeg");
    }
  }
}