// Vendor
@import "~normalize-scss/sass/normalize";
@import "~bootstrap-icons/font/bootstrap-icons.css";

// Shared
@import "~@amf/shared/styles/variables";

// Components
@import "components/content/structure";
@import "components/content/repre-promote";
@import "components/tournament/tournamentList";
@import "components/tournament/tournamentListItem";
@import "components/tournament/tournamentMap";
@import "components/tournament/tournamentFilter";
@import "components/tournament/tournamentCard";
@import "components/tournament/tournamentEmpty";
@import "components/tournament/tournamentForm";
@import "components/union/unionSlider";
@import "components/ranking/rankingTable";

// Pages
@import "page/homePage";
@import "page/newsDetailPage";
@import "page/unionListPage";
@import "page/tournamentListPage";
@import "page/tournamentDetailPage";
@import "page/tournamentCreatePage";
@import "page/contactPage";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
