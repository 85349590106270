

.TournamentList {
  display: flex;
  margin-bottom: 24px;
  flex-grow: 1;
  flex-direction: column;
  gap: 24px;

  &--withBackground {
    background: var(--color-grey);
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    background: var(--color-grey);

    @media (max-width: $break-large) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-medium) {
      grid-template-columns: 1fr;
    }
  }

  @media (min-width: $break-large + 1px) {
    &__content > &Item:nth-child(2n):not(:hover) {
      background: var(--color-grey-10);
    }
  }

  @media (max-width: $break-large) and (min-width: $break-medium + 1px) {
    &__content > &Item:nth-child(4n):not(:hover),
    &__content > &Item:nth-child(4n + 1):not(:hover) {
      background: var(--color-grey-10);
    }
  }

  @media (max-width: $break-medium) {
    &__content > &Item:nth-child(2n):not(:hover) {
      background: var(--color-grey-10);
    }
  }

  &__create {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    color: var(--color-white);
    background: var(--color-blue-20);
    padding: 24px 0;

    &Title {
      margin: 0 0 4px;
      color: currentColor;
    }

    &Message {
      font-size: 16px;
      color: currentColor;
      max-width: 220px;
      margin-bottom: 16px;
    }
  }
}